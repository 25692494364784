export default defineNuxtRouteMiddleware((_to, _from) => {
	// skip middleware on server
	if (import.meta.server) return;
	// skip middleware on client side entirely
	// if (import.meta.client) return;

	// or only skip middleware on initial client load
	// const nuxtApp = useNuxtApp();
	// if (import.meta.client && nuxtApp.isHydrating && nuxtApp.payload.serverRendered) return;
	const purchaseTicketStore = usePurchaseTicketStore();
	const { purchaseTicket } = storeToRefs(purchaseTicketStore);

	if (!purchaseTicket.value.tickets || purchaseTicket.value.tickets.length == 0) {
		return navigateTo(`/events/${useRoute().params.id}`, { replace: true });
	}
});
